import { LoadChildren, Route } from '@angular/router';
import { Role } from '@core/auth/auth.model';
import { FeatureAllowedGuard } from '@core/auth/feature-allowed.guard';

interface FeatureRoute {
  path: string;
  featureModule: Function;
  alias: string;
  icon: string;
  roles: Role[];
}

export const features: FeatureRoute[] = [
  {
    path: 'atms',
    featureModule: () =>
      import('@features/atms/atms.module').then((m) => m.AtmsModule),
    alias: 'pages.atms',
    icon: 'atms',
    roles: ['01', '02', '03', '04'],
  },
  {
    path: 'devices',
    featureModule: () =>
      import('@features/devices/devices.module').then((m) => m.DevicesModule),
    alias: 'pages.devices',
    icon: 'settings_remote',
    roles: ['01', '02', '03', '04'],
  },
  {
    path: 'alerts',
    featureModule: () =>
      import('@features/alerts/alerts.module').then((m) => m.AlertsModule),
    alias: 'pages.alarms',
    icon: 'notifications',
    roles: ['01', '02', '03', '04'],
  },
  {
    path: 'maintenance',
    featureModule: () =>
      import('@features/maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule
      ),
    alias: 'pages.maintenance',
    icon: 'engineering',
    roles: ['01', '02', '03', '04'],
  },
  {
    path: 'reports',
    featureModule: () =>
      import('@features/reports/reports.module').then((m) => m.ReportsModule),
    alias: 'pages.reports',
    icon: 'assignment',
    roles: ['01', '02', '03', '04'],
  },
  {
    path: 'administration',
    featureModule: () =>
      import('@features/administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
    alias: 'pages.administration',
    icon: 'settings',
    roles: ['01'],
  },
  {
    path: 'calendar',
    featureModule: () =>
      import('@features/calendar/calendar.module').then(
        (m) => m.CalendarModule
      ),
    alias: 'pages.calendar',
    icon: 'calendar_today',
    roles: ['01'],
  },
  {
    path: 'installation',
    featureModule: () =>
      import('@features/installation/installation.module').then(
        (m) => m.InstallationModule
      ),
    alias: 'pages.installation',
    icon: 'engineering',
    roles: ['01', '02', '03', '04', '05'],
  },
];

export const featureRoutes: Route[] = features.map(
  ({ path, featureModule, roles }) => ({
    path,
    loadChildren: featureModule as LoadChildren,
    canActivate: [FeatureAllowedGuard],
    canLoad: [FeatureAllowedGuard],
    data: { roles },
  })
);
