<div class="root">
  <ik-header
    [name]="'title' | transloco"
    [compactMode]="mobileMode"
    [showMenuIcon]="pages.length > 1"
    (toggleMenu)="toggle()"
  >
  </ik-header>
  <div class="main-container" *ngIf="!loading">
    <div *ngIf="mobileMode; then mobileLayout; else desktopLayout"></div>
    <ng-template #mobileLayout>
      <div class="mobile-container">
        <ik-menu
          *ngIf="loggedIn && pages.length > 1"
          [collapsed]="false"
          [options]="pages"
          [opened]="opened"
          (click)="menuItemSelected()"
        ></ik-menu>

        <div class="page-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </ng-template>
    <ng-template #desktopLayout>
      <mat-sidenav-container autosize>
        <mat-sidenav
          #sidenav
          mode="side"
          [opened]="opened && loggedIn && pages.length > 1"
        >
          <ik-menu
            [collapsed]="collapsed"
            [options]="pages"
            (click)="menuItemSelected()"
          ></ik-menu>
        </mat-sidenav>
        <mat-sidenav-content>
          <div class="page-container">
            <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </ng-template>
  </div>
  <ik-loading class="main-container" *ngIf="loading"> </ik-loading>

  <ik-footer></ik-footer>
</div>
