import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from '@env/environment';
import { AuthService } from './auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './auth-interceptor.service';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authWellknownEndpoints: {
          issuer: environment.auth.url,
          jwksUri: `${environment.auth.url}/.well-known/jwks.json`,
          authorizationEndpoint: `${environment.auth.endpointsUrl}/oauth2/authorize`,
          tokenEndpoint: `${environment.auth.endpointsUrl}/oauth2/token`,
          userInfoEndpoint: `${environment.auth.endpointsUrl}/oauth2/userInfo`,
          revocationEndpoint: `${environment.auth.endpointsUrl}/oauth2/revoke`,
          endSessionEndpoint: `${environment.auth.endpointsUrl}/logout`,
        },
        authority: environment.auth.url,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.auth.clientId,
        scope: 'openid email',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        logLevel: LogLevel.Error,
        unauthorizedRoute: 'http://localhost:4200/unauthorized',
        secureRoutes: [environment.apiUrl],
        customParamsEndSessionRequest: {
          client_id: environment.auth.clientId,
          logout_uri: window.location.origin,
        },
      },
    }),
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
