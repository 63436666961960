import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class DataInterceptorService implements HttpInterceptor {
  constructor(private snackBarService: MatSnackBar) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status !== 401 && err.status !== 504) {
          this.openSnackBar(err.message, 'ERROR');
        }
        return throwError(err);
      })
    );
  }

  openSnackBar(message: string, action: string) {
    const config: MatSnackBarConfig = {
      duration: 5000,
      verticalPosition: 'bottom',
      panelClass: ['mat-toolbar', 'mat-warn'],
    };
    this.snackBarService.open(message, action, config);
  }
}
