<h1>
  <div>
    {{ "home.message" | transloco }}
  </div>
  <div>
    <button mat-icon-button (click)="login()">
      <mat-icon>login</mat-icon>
      <span> {{ "authentication.loginMessage" | transloco }} </span>
    </button>
  </div>
</h1>
