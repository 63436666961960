<div *ngIf="opened; then menuOpened; else menuClosed"></div>
<ng-template #menuOpened>
  <mat-nav-list class="menu">
    <a
      mat-list-item
      class="list-item"
      [ngClass]="{
        selected: selectedOption && selectedOption.link === option.link
      }"
      [routerLink]="option.link"
      *ngFor="let option of options"
    >
      <div class="list-item-content">
        <mat-icon aria-hidden="false" class="option-icon" aria-label="icon">{{
          option.icon
        }}</mat-icon>
        <span *ngIf="!collapsed" class="option-name">
          {{ option.alias | transloco }}</span
        >
      </div>
    </a>
  </mat-nav-list>
</ng-template>

<ng-template #menuClosed>
  <mat-toolbar color="accent" *ngIf="selectedOption">
    <span>{{ selectedOption.alias | transloco }}</span>
  </mat-toolbar>
</ng-template>
