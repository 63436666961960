import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'ik-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    this.auth.userAuthenticated().subscribe(({ value }) => {
      setTimeout(() => this.auth.navigateToInitialFeature(), 100);
    });
  }

  login() {
    this.auth.login();
  }
}
