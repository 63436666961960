import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuOption } from '@core/components/menu/menu.component';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { tap, catchError, take, filter } from 'rxjs/operators';
import { features } from 'src/app/app-features';
import {
  EventTypes,
  OidcSecurityService,
  PublicEventsService,
} from 'angular-auth-oidc-client';
import jwt_decode from 'jwt-decode';
import { Role } from './auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userChange: BehaviorSubject<null> = new BehaviorSubject(null);
  unAuthenticatedSub?: Subscription;

  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private eventService: PublicEventsService
  ) {}

  isAuthenticated(): boolean {
    return this.oidcSecurityService.isAuthenticated();
  }

  isAuthenticated$() {
    return this.oidcSecurityService.isAuthenticated$;
  }

  logout() {
    this.unAuthenticatedSub?.unsubscribe();
    try {
      const configId = this.oidcSecurityService.getConfiguration().configId;
      const logoffAndRevokeToken = this.oidcSecurityService
        .revokeRefreshToken(configId)
        .pipe(
          catchError((error) => {
            const errorMessage = `revoke accessToken failed`;
            console.error(configId, errorMessage, error);
            return throwError(() => new Error(errorMessage));
          }),
          tap(() => this.oidcSecurityService.logoff(configId)),
          take(1)
        );

      logoffAndRevokeToken.subscribe((c) => console.log(c));
    } catch (error) {
      console.log(error);
    }
  }

  login() {
    this.oidcSecurityService.authorize();
    this.unAuthenticatedSub = this.oidcSecurityService.userData$.subscribe(
      (res) => {
        if (res.userData === null) {
          this.navigateToInitialFeature();
        }
      }
    );
  }

  userAuthenticated(): Observable<any> {
    return this.eventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.NewAuthenticationResult
        )
      );
  }

  hasRole(roles: Role[]) {
    const role = this.getUserRole() as Role;
    return roles.includes(role);
  }

  getUsername(): string {
    const username = this.oidcSecurityService.getUserData()?.username;
    if (username && username.startsWith('saml-nauthilus_')) {
      return username.replace('saml-nauthilus_', '');
    }
    return username;
  }

  getEmail(): string {
    return this.oidcSecurityService.getUserData()?.email;
  }

  getUserRole(): string {
    const idToken: string = this.oidcSecurityService.getIdToken();
    if (idToken) {
      const decodedIdToken: any = jwt_decode(
        this.oidcSecurityService.getIdToken()
      );
      return decodedIdToken?.role || '';
    } else {
      return '';
    }
  }

  getAllowedMenuOptions() {
    return this.menuOptionsByRole(this.getUserRole());
  }

  getToken(): string {
    return this.oidcSecurityService.getIdToken();
  }

  navigateToInitialFeature() {
    const initialFeaturePath: string = this.getInitialFeaturePath();
    //console.info(`Navigate to ${initialFeaturePath}`);
    return this.router.navigateByUrl(initialFeaturePath);
  }

  private getInitialFeaturePath(): string {
    return this.initialFeaturePathByRole(this.getUserRole());
  }

  private menuOptionsByRole(role: string): MenuOption[] {
    return features
      .filter(({ roles }) => roles.includes(role as Role))
      .map(({ alias, icon, path }) => ({
        alias: alias,
        icon: icon,
        link: path,
      }));
  }

  private initialFeaturePathByRole(role: string): string {
    const allowedFeatures = features.filter(({ roles }) =>
      roles.includes(role as Role)
    );
    if (allowedFeatures.length) {
      return allowedFeatures[0].path;
    }
    return '';
  }
}
