<button mat-menu-item [matMenuTriggerFor]="langMenu">Language</button>
<mat-menu #langMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let lang of langs"
    (click)="changeLanguage(lang)"
  >
    {{ lang }}
  </button>
</mat-menu>
