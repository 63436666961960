import { Component, Input, OnInit } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ik-lang-menu',
  templateUrl: './lang-menu.component.html',
  styleUrls: ['./lang-menu.component.scss'],
})
export class LangMenuComponent implements OnInit {
  langs: any[] = [];

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.langs = this.translocoService.getAvailableLangs();
  }

  changeLanguage(language: string) {
    this.translocoService.setActiveLang(language);
  }
}
