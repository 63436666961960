import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated$().pipe(
      switchMap(({ isAuthenticated }) => {
        if (isAuthenticated) {
          return this.authService.navigateToInitialFeature();
        } else {
          return of(true);
        }
      })
    );
  }
}
