import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeGuard } from '@core/auth/home.guard';
import { HomeComponent } from '@core/components/home/home.component';

import { UnauthorizedComponent } from '@core/components/unauthorized/unauthorized.component';

import { featureRoutes } from './app-features';

const routes: Routes = [
  ...featureRoutes,
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [HomeGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {}
}
