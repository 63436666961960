import { NgModule, Optional, SkipSelf } from '@angular/core';
import { I18nModule } from './i18n/i18n.module';

import { HeaderComponent } from './components/header/header.component';
import { LangSelectorComponent } from './components/lang-selector/lang-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './components/menu/menu.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { LangMenuComponent } from './components/lang-menu/lang-menu.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { HomeComponent } from './components/home/home.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataInterceptorService } from './data/data-interceptor.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const materialModules: any[] = [
  MatIconModule,
  MatSidenavModule,
  MatListModule,
  MatInputModule,
  MatRadioModule,
  MatChipsModule,
  MatCardModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatToolbarModule,
  MatButtonModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSortModule,
  MatDialogModule,
  MatProgressSpinnerModule,
];

@NgModule({
  declarations: [
    HeaderComponent,
    LangSelectorComponent,
    FooterComponent,
    MenuComponent,
    LangMenuComponent,
    UnauthorizedComponent,
    HomeComponent,
    LoadingComponent,
  ],
  imports: [
    I18nModule,
    ...materialModules,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    CommonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataInterceptorService,
      multi: true,
    },
  ],
  exports: [
    I18nModule,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    LoadingComponent,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only.'
      );
    }
  }
}
