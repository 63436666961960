<mat-form-field appearance="legacy" class="language-select">
  <mat-select
    [value]="currentLanguage"
    (selectionChange)="changeLanguage($event)"
  >
    <mat-option *ngFor="let lang of langs" [value]="lang">{{
      lang
    }}</mat-option>
  </mat-select>
</mat-form-field>
