import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { Subscription } from 'rxjs';

export interface MenuOption {
  link: string;
  alias: string;
  icon: string;
}

@Component({
  selector: 'ik-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input()
  collapsed = false;
  @Input()
  options: MenuOption[] = [];
  @Input()
  opened: boolean = true;
  selectedOption: MenuOption | undefined;

  routerSubscription: Subscription | undefined;

  constructor(public router: Router, private auth: AuthService) {}

  findSelected(url: string): MenuOption | undefined {
    const link: string = url.substr(1);
    return this.options.find((opt) => link.startsWith(opt.link));
  }

  ngOnInit(): void {
    this.selectedOption = this.findSelected(this.router.url);

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedOption = this.findSelected(event.urlAfterRedirects);
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }
}
