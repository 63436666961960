import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Data,
  Route,
  Router,
  UrlTree,
} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureAllowedGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private oidcSecurityService: OidcSecurityService
  ) {}

  canLoad(route: Route): boolean | Promise<boolean> {
    const data = route.data;
    if (data) {
      if (this.hasAccessToRoute(data)) {
        return true;
      }
    }
    return this.router.navigateByUrl('unauthorized');
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    if (!this.authService.isAuthenticated()) {
      this.authService.login();
      return false;
    } else {
      if (this.hasAccessToRoute(route.data)) {
        return true;
      }
    }
    return this.router.navigateByUrl('unauthorized');
  }

  private hasAccessToRoute(data: Data) {
    const routeRoles: any[] = data.roles || [];
    return routeRoles.includes(this.authService.getUserRole());
  }
}
