<mat-toolbar color="primary" class="toolbar" *transloco="let t">
  <div *ngIf="compactMode; then compactToolbar; else desktopToolbar"></div>
  <ng-template #compactToolbar>
    <button
      *ngIf="loggedIn && showMenuIcon"
      mat-icon-button
      (click)="onClick()"
      class="menu-icon"
      aria-label="Menu button with menu icon"
    >
      <span class="mat-headline"><mat-icon>menu</mat-icon></span>
    </button>
    <div class="logo-container">
      <img class="header-logo compact" [src]="logoSrc" />
    </div>
    <button
      *ngIf="loggedIn"
      mat-icon-button
      class="menu-icon"
      aria-label="User icon"
      [matMenuTriggerFor]="beforeMenu"
    >
      <span class="mat-headline"><mat-icon>account_circle</mat-icon></span>
    </button>
    <mat-menu #beforeMenu="matMenu" xPosition="before">
      <button mat-menu-item disabled>
        <span>{{ t("welcome") }}, {{ username }}</span>
      </button>
      <ik-lang-menu></ik-lang-menu>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>logout</span>
      </button>
    </mat-menu>
  </ng-template>
  <ng-template #desktopToolbar>
    <img class="header-logo" [src]="logoSrc" />
    <span class="title mat-headline">
      {{ name }}
    </span>
    <span class="spacer"></span>
    <ik-lang-selector></ik-lang-selector>
    <div class="user-info" *ngIf="loggedIn && !compactMode">
      <span>{{ t("welcome") }}, {{ username }}</span>
      <button mat-icon-button aria-label="Settings icon" (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
    <button mat-icon-button (click)="login()" *ngIf="!loggedIn && !compactMode">
      <mat-icon>login</mat-icon>
      <span>login</span>
    </button>
  </ng-template>
</mat-toolbar>
