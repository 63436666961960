import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  mobileMode = true;
  topMenu = false;
  opened = false;
  collapsed = false;
  sidenavMode = 'side' as MatDrawerMode;
  breakpointSubscription: Subscription | undefined;

  @ViewChild('sidenav') sidenav: MatSidenav | undefined;

  get loggedIn(): boolean {
    return this.auth.isAuthenticated();
  }

  pages: any = [];
  sub?: Subscription;
  loading: boolean = true;

  constructor(
    private auth: AuthService,
    private breakpointObserver: BreakpointObserver,
    private oidcSecurityService: OidcSecurityService
  ) {}

  ngOnInit() {
    this.oidcSecurityService
      .checkAuth()
      .subscribe(({ isAuthenticated, userData, accessToken }) => {
        this.loading = false;
        this.pages = this.auth.getAllowedMenuOptions();
      });

    this.opened = true;

    this.breakpointSubscription = this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.mobileMode = true;
          this.opened = false;
        } else {
          this.mobileMode = false;
          this.opened = true;
        }
        if (state.breakpoints[Breakpoints.Small]) {
          this.collapsed = true;
          this.opened = true;
        }
        if (state.breakpoints[Breakpoints.Medium]) {
          this.collapsed = true;
          this.opened = true;
        }
        if (state.breakpoints[Breakpoints.Large]) {
          this.collapsed = false;
          this.opened = true;
        }
        if (state.breakpoints[Breakpoints.XLarge]) {
          this.collapsed = false;
          this.opened = true;
        }
      });
  }

  ngOnDestroy() {
    this.breakpointSubscription?.unsubscribe();
  }

  menuItemSelected() {
    if (this.mobileMode) {
      this.opened = false;
    }
  }

  toggle() {
    this.opened = !this.opened;
  }
}
