import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ik-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
})
export class LangSelectorComponent implements OnInit {
  langs: any[] = [];

  constructor(private translocoService: TranslocoService) {}

  get currentLanguage() {
    return this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    this.langs = this.translocoService.getAvailableLangs();
  }

  changeLanguage(event: any) {
    const language = event.value;
    this.translocoService.setActiveLang(language);
  }
}
