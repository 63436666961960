<h1>
  <div>
    <mat-icon aria-hidden="false">do_not_disturb_alt</mat-icon>
    {{ "authentication.notAccessMessage" | transloco }}
  </div>
  <div>
    <button mat-icon-button (click)="login()">
      <mat-icon>login</mat-icon>
      <span> {{ "authentication.loginMessage" | transloco }} </span>
    </button>
  </div>
</h1>
