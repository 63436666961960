import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'ik-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input()
  name!: string;
  @Input()
  logoSrc: string = environment.logoPath;
  @Input()
  compactMode = false;
  @Input()
  showMenuIcon = true;
  @Output()
  toggleMenu: EventEmitter<void> = new EventEmitter<void>();

  get loggedIn(): boolean {
    return this.auth.isAuthenticated();
  }

  get username(): string {
    return this.auth.getUsername();
  }

  constructor(private auth: AuthService) {}

  ngOnInit(): void {}

  onClick() {
    this.toggleMenu.emit();
  }

  logout() {
    this.auth.logout();
  }

  login() {
    this.auth.login();
  }
}
