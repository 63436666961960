import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  apiUrl: 'https://oxj64lkj92.execute-api.eu-west-1.amazonaws.com/dev',
  production: true,
  auth: {
    url: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_U3aNmxty1',
    endpointsUrl: 'https://bbva-atm.auth.eu-west-1.amazoncognito.com',
    clientId: 'qhl0nbu02hh1r2ojfsl4jcu7q',
  },
  langs: ['en', 'es'],
  defaultLang: 'es',
  fallbackLang: 'es',
  logoPath: 'assets/img/logo_bbva.svg',
  refreshTime: 30000,
  heartbeatIntervalMin: 2,
  heartbeatIntervalMax: 2880,
  heartbeatIntervalDef: undefined,
  temperatureThresholdMin: 5,
  temperatureThresholdMax: 80,
  temperatureThresholdDef: undefined,
  lightThresholdMin: 0.1,
  lightThresholdMax: 1.8,
  lightThresholdDef: undefined,
  vibrationThresholdMin: 1,
  vibrationThresholdMax: 10000,
  vibrationThresholdDef: undefined,
  inclinationThresholdMin: 1,
  inclinationThresholdMax: 30,
  inclinationThresholdDef: undefined,
  movementThresholdMin: 0.01,
  movementThresholdMax: 10,
  movementThresholdDef: undefined,
  noHeartbeatOffsetMin: 5,
  noHeartbeatOffsetMax: 3000,
  noHeartbeatOffsetDef: undefined,
  lowBatteryThresholdMin: 2,
  lowBatteryThresholdMax: 4,
  lowBatteryThresholdDef: undefined,
  lowBatteryOffsetMin: 5,
  lowBatteryOffsetMax: 43200,
  lowBatteryOffsetDef: undefined
};
